import ReactDOM  from 'react-dom';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { XLg } from 'react-bootstrap-icons';
import { getRequestObjectFromForm, clearForm, compose, notNull, notBlank, validEmail, validPhoneNumber, validLength, validateRequest } from './formUtils.mjs';

const apiURL = process.env.REACT_APP_API_URL; 

const FormContext = createContext();

function FormInput({ name, label }) {
  const ref = useRef();
  const { validations, fieldErrors } = useContext(FormContext);
  const [error, setError] = useState(fieldErrors && fieldErrors[name] ? fieldErrors[name] : null);

  useEffect(() => {
    setError(fieldErrors && fieldErrors[name] ? fieldErrors[name] : null);
  }, [fieldErrors]);

  const validate = () => {
    if (validations && validations[name] && ref.current) {
      const err = validations[name](ref.current.value);
      setError(err);
    } else if (error) { 
      setError(null);
    }
  }
  
  return (
    <div className='inputGroup'>
      <label htmlFor={name}>{label}</label>
      <input ref={ref} name={name} type='text' onBlur={validate} />
      {error ? <div className='error'>{error}</div> : <></>}
    </div>
  )
}

function WaitlistForm({ close }) {
  const formId = 'WAITLIST_FORM';
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState();
  const [error, setError] = useState();

  const validations = {
    name: compose(
      notNull('Name is required'),
      notBlank('Name is required'),
      validLength(2, 255, 'Name must be between 2 and 255 characters.')
    ),
    email: compose(
      notNull('Email is required'),
      notBlank('Email is required'),
      validEmail('Email invalid')
    ),
    phone: compose(
      notBlank('Phone number cannot be blank'),
      validPhoneNumber('Phone number is invalid.')
    ),
    location: compose(
      notBlank('Location cannot be blank'),
      validLength(0, 255, 'Location cannot exceed 255 characters.')
    )
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const request = getRequestObjectFromForm(formId);
    const requestErrors = validateRequest(request, validations);
    setFieldErrors(requestErrors);

    if (requestErrors && Object.keys(requestErrors)?.length > 0) {
      return;
    }

    if (request.phone) {
      request.phone = request.phone.replace(/[\+()\s-]/g, '');
    }

    setLoading(true);

    const headers =  { 'Content-Type': 'application/json', 'Access-Control-Request-Headers': 'Content-Type' };
    fetch(`${apiURL}/waitlist`, {method: 'POST', mode: 'cors', credentials: 'include', headers: headers, body: JSON.stringify(request)})
      .then((res) => {
        if (res.ok) {
          setError(null);
          close();
          clearForm(formId);
        } else {
          res.json()
          .then((e) => {
            console.log('Got error', e);
            if (e?.message) {
              setError(e.message);
            } else {
              setError('An unknown error occurred.');
            }
          })
          .catch((err) => {
            console.error('Failed to parse error response', err);
          });
        }
        setLoading(false);
      }).catch((err) => {
        console.error('Failed to add to waitlist', err);
        setLoading(false);
        setError('Failed to join waitlist.')
      });
  }

  return (
    <FormContext.Provider value={{validations: validations, fieldErrors: fieldErrors}}>
      <form id={formId} className='waitlist'>
        <FormInput name='name' label='Name' />
        <FormInput name='email' label='Email' />
        <FormInput name='phone' label='Phone (optional)' />
        <FormInput name='location' label='Location (optional)' />
        <input type='submit' onClick={onSubmit} disabled={loading} />
        {error ? <div className='formError'>{error}</div> : <></>}
      </form>
    </FormContext.Provider>
  )
}

export function WaitlistFormModal({ open, setOpen }) {
  const onClickClose = () => setOpen(!open);
  return ReactDOM.createPortal(
    <div className='outerModal' style={open ? {display: 'flex'} : {display: 'none'}}>
      <div className='modal'>
        <header>
          <XLg size={20} onClick={onClickClose} />
          <h1>Join Waitlist</h1>
        </header>
        <div className='children'>
          <WaitlistForm close={onClickClose} />
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
}
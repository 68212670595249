import { useState } from 'react';
import { WaitlistFormModal } from './WaitlistForm.mjs';
import { NavLink } from 'react-router';

export function Waitlist() {
  const [open, setOpen] = useState(false);

  return (
    <div className="app">
      <main>
        <h1>RESCOPE</h1>
        <div className='focus'>
          <span>buy</span>
          <span>&#183;</span>
          <span>sell</span>
          <span>&#183;</span>
          <span>style</span>
        </div>
        <button className='join' onClick={() => setOpen(!open)}>Join Waitlist</button>
        <p className='policyDisclaimer'>By continuing, you agree to our <NavLink to='/terms-and-conditions'>Terms and Conditions</NavLink> and <NavLink to='/privacy-policy'>Privacy Policy</NavLink>.</p>
      </main>
      <WaitlistFormModal open={open} setOpen={setOpen} />
    </div>
  )
}
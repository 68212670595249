import { NavLink } from "react-router";

export function TermsAndConditions() {
  return (
    <div className='policyOuterContainer'>
      <NavLink to='/'>
        <h1>RESCOPE</h1>
      </NavLink>
      <div className='policyContainer'>
        <h2 className='desktopHeader'>Terms & Conditions</h2>
        <section>
          <header>
            <h3>1. Introduction</h3>
          </header>
          <p>Welcome to Rescope! These Terms of Service are a legally binding contract between you and Rescope regarding your use of the Rescope website, mobile applications, and related services (collectively, the "Service"). Please read these Terms carefully before using the Service. By accessing or using the Service, you agree to be bound by these Terms.</p>
        </section>
        <section>
          <header>
            <h3>2. Eligibility and Accounts</h3>
          </header>
          <p>You must be at least 18 years old to register for an account and use the Service. Minors may use the Service only under the supervision of a parent or legal guardian, who must consent to these Terms.</p>
          <p>You are responsible for keeping your account password confidential. Contact Rescope immediately if you discover or suspect unauthorized use of your account.</p>
        </section>
        <section>
          <header>
            <h3>3. Allowed Uses of the Service</h3>
          </header>
          <p>You agree to access and use the Service only for lawful, proper and authorized purposes. You may not use the Service to violate any laws or rights of third parties. Examples of prohibited activities include:</p>
          <ul>
            <li>Discrimination, threats, stalking, harassment and other illegal conduct</li>
            <li>Infringing intellectual property rights</li>
            <li>Misrepresentation, fraud, or scams</li>
            <li>Spamming other Rescope users or the Service</li>
          </ul>
          <p>You are solely responsible for your interactions with other users and your activity through the Service.</p>
        </section>
        <section>
          <header>
            <h3>4. User Content</h3>
          </header>
          <p>You retain ownership rights in the content you submit, post, display or otherwise make available ("Post") through the Service.  By Posting content, you grant Rescope a worldwide, non-exclusive license to use, reproduce, distribute and make derivative works from the content solely to operate and improve the Service.</p>
          <p>Rescope may reject, modify, block, or remove user content if Rescope deems the content violates these Terms or applicable laws. Removed content may continue to be stored by Rescope as required by law.</p>
        </section>
        <section>
          <header>
            <h3>5. Rescope's Role</h3>
          </header>
          <p>Rescope provides an online platform to enable users to connect for the purpose of buying and selling items. Rescope is not a party to any transactions between users. Users are solely responsible for all aspects of those transactions.</p>
        </section>
        <section>
          <header>
            <h3>6. Payment Processing</h3>
          </header>
          <p>All payments must be conducted through Rescope. Attempting transactions outside of the service is strictly prohibited.</p>
          <p>Rescope partners with Stripe for all payment processing. Sellers are subject to the <a href='https://stripe.com/legal/connect-account' target='_blank'>Stripe Connected Account Agreement</a>.</p>
        </section>
        <section>
          <header>
            <h3>7. Shipping and Delivery</h3>
          </header>
          <p>Sellers must properly package, address and ship items to buyers in a timely manner. Buyers may be eligible for refunds through Rescope's Buyer Protection program if items are lost or damaged in shipping.</p>
        </section>
        <section>
          <header>
            <h3>8. Refunds and Cancellations</h3>
          </header>
          <p>Buyers may request a refund if an item purchased through Rescope:</p>
          <ul>
            <li>Does not match the seller's description due to damage, defects, missing pieces or other significant ways it varies from the posted listing</li>
            <li>Is lost or damaged during shipping</li>
          </ul>
          <p>To be eligible for a refund, buyers must report any issues to Rescope within 48 hours after the delivery date. Refunds cover the full purchase amount paid, including Rescope fees, shipping costs and taxes. Refunds are issued to the original payment method.</p>
          <p>Sellers may choose to offer buyers the option to return items for a refund, replace items or provide other remedies outside of Rescope's Buyer Protection program. However, sellers remain responsible for complying with all applicable laws regarding refunds and exchanges.</p>
          <p>Sellers are prohibited from withdrawing or canceling a sale after the buyer has paid, except by mutual consent of the parties involved or if compelled to do so by Rescope to resolve a user complaint.</p>
          <p>Buyers may request cancellation of an order within 12 hours of placing it for any reason. If an item has already shipped, it can still be returned but any shipping costs incurred will not be refunded.</p>
        </section>
        <section>
          <header>
            <h3>9. Account Suspension and Termination</h3>
          </header>
          <p>You may close your Rescope account at any time. Rescope reserves the right to immediately suspend or terminate accounts if Rescope determines a user has materially breached these Terms, violated applicable laws, or engaged in fraud, illegal transactions or infringement. Rescope will attempt to notify users unless prohibited by law or urgent issues.</p>
        </section>
        <section>
          <header>
            <h3>10. Disclaimer of Service Warranties</h3>
          </header>
          <p>The Service is provided on an "as is" and "as available" basis without warranties. Rescope expressly disclaims any guarantees regarding availability, accuracy, quality, safety or ability to complete transactions.</p>
        </section>
        <section>
          <header>
            <h3>11. Limitation of Liability</h3>
          </header>
          <p>Rescope has no liability for interactions between users. Rescope also is not liable for lost profits, reputational harm, or any indirect, incidental or consequential damages relating to use of the Service.</p>
        </section>
        <section>
          <header>
            <h3>12. Indemnification</h3>
          </header>
          <p>You agree to defend, indemnify and hold harmless Rescope, its affiliates, and their officers, directors, employees and agents from any claims, damages, losses, liabilities, and expenses (including attorneys' fees) arising out of your use of the Service, your User Content, your violation of these Terms, or your violation of any rights of another party.</p>
        </section>
        <section>
          <header>
            <h3>13. No Warranty of User Content</h3>
          </header>
          <p>Rescope does not guarantee the accuracy, integrity or quality of user content posted on the Service. You understand that by using the Service, you may be exposed to content that is offensive or objectionable. Rescope has no liability for user content.</p>
        </section>
        <section>
          <header>
            <h3>14. Arbitration</h3>
          </header>
          <p>You and Rescope agree to resolve any disputes through binding individual arbitration instead of in court. Class action lawsuits and classwide arbitration are prohibited.</p>
        </section>
        <section>
          <header>
            <h3>15. Governing Law</h3>
          </header>
          <p>These Terms are governed by the laws of the State of Delaware without regard to conflict of law provisions. Non-arbitration legal disputes must be handled exclusively in state or federal courts located in Delaware.</p>
        </section>
        <section>
          <header>
            <h3>16. Changes to Terms</h3>
          </header>
          <p>Rescope may amend these Terms by posting an updated version. Changes are effective once posted. If users do not agree with updated Terms, they may close their Rescope account. Continued use constitutes acceptance.
  </p>
        </section>
        <section>
          <header>
            <h3>17. Contact Information</h3>
          </header>
          <p>Please direct any questions regarding these Terms to <strong>support@rescope.xyz</strong>.</p>
        </section>
      </div>
    </div>
  )
}
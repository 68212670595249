import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router';
import { Waitlist } from './Waitlist.mjs';
import { TermsAndConditions } from './TermsAndConditions.mjs';
import { PrivacyPolicy } from './PrivacyPolicy.mjs';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<Waitlist />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;

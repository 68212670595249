import { NavLink } from "react-router";

export function PrivacyPolicy() {
  return (
    <div className='policyOuterContainer'>
      <NavLink to='/'>
        <h1>RESCOPE</h1>
      </NavLink>
      <div className='policyContainer'>
        <h2 className='desktopHeader'>Privacy Policy</h2>
        <section>
          <header>
            <h3>Introduction</h3>
          </header>
          <p>Welcome to <strong>Rescope</strong> (“<strong>Rescope</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “<strong>our</strong>”). This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our mobile application, website, or any other services we provide (collectively, the “<strong>Services</strong>”). We take your privacy seriously and are committed to handling your personal information in a secure and responsible manner.</p>
          <p><strong>PLEASE READ THIS POLICY CAREFULLY TO UNDERSTAND OUR PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. BY USING OUR SERVICES, YOU AGREE TO THE TERMS OF THIS POLICY. IF YOU DO NOT AGREE WITH THE PRACTICES DESCRIBED IN THIS POLICY, PLEASE DO NOT USE OUR SERVICES.</strong></p>
        </section>
        <section>
          <header>
            <h3>1. Information We Collect</h3>
          </header>
          <section>
            <h4>1.1. Personal Information</h4>
            <p>When you create an account with Rescope, we may collect information such as your name, email address, password, phone number, mailing address, or other similar details.</p>
          <p>You may also provide additional information to build your profile, including profile photo, username, display name, and other optional details you choose to share.</p>
          </section>
          <section>
            <h4>1.2. Transaction Information</h4>
            <p>If you make transactions through our platform (buying or selling items), we may collect information related to your payment method (e.g., credit card details), billing address, or other transaction-related details.</p>
          </section>
          <section>
            <h4>1.3. Usage Information</h4>
            <p>We automatically collect certain data about how you use our Services. This includes information such as your device type, operating system, browser type, IP address, unique device identifiers, referral URLs, pages visited, and search terms.</p>
          </section>
          <section>
            <h4>1.4. Location Information</h4>
            <p>We may request permission to collect or process your location data if you choose to enable location services on your device. This helps us provide location-based features (e.g., localized search results).</p>
          </section>
          <section>
            <h4>1.5. Cookies and Similar Technologies</h4>
            <p>We may use cookies, web beacons, or similar tracking technologies to collect information about your interactions with our Services (e.g., remembering your preferences, recognizing you when you return, and personalizing ads). You can manage your cookie preferences through your browser settings.</p>
          </section>
          <section>
            <h4>1.6. Information from Third Parties</h4>
            <p>We may receive information about you from third parties, such as social media services, payment processors, or other partners, if you integrate or link a third-party service with our Services.</p>
          </section>
        </section>
        <section>
          <header>
            <h3>2. How We Use Your Information</h3>
          </header>
          <p>We use the information we collect for various purposes, including:</p>
          <ul>
            <li>Providing and Maintaining Our Services: To create, manage, and authenticate your account; enable transactions; facilitate listings and communications between buyers and sellers; and otherwise operate our Services.</li>
            <li>Improving and Customizing Our Services: To understand how you use the Services, develop new features, and personalize the content, products, and services we provide.</li>
            <li>Communication: To send you updates, administrative messages, and information about products or services that may be of interest to you, consistent with your communication preferences.</li>
            <li>Payment Processing: To process your payments and prevent fraud or abuse of our payment systems.</li>
            <li>Compliance with Legal Obligations: To comply with applicable laws, regulations, or legal processes (e.g., responding to subpoenas or court orders).</li>
            <li>Enforcement and Security: To detect, investigate, and prevent fraudulent transactions and other illegal activities, protect the rights and property of Rescope and others, and enforce our agreements and policies.</li>
          </ul>
        </section>
        <section>
          <header>
            <h3>3. How We Share Your Information</h3>
          </header>
          <p>We do not sell or rent your personal information. However, we may share your information in the following circumstances:</p>
          <ul>
            <li>With Other Users: If you interact with other users (e.g., making a purchase or listing an item), certain information such as your username, profile photo, or transaction details may be shared.</li>
            <li>Service Providers: We may share your information with third-party service providers who help us operate, maintain, or improve our Services (e.g., hosting providers, payment processors, analytics providers, customer support).</li>
            <li>Business Transfers: In the event of a merger, acquisition, financing, or sale of assets, or in any other situation involving the transfer of some or all of our business assets, we may share or transfer your information, subject to standard confidentiality arrangements.</li>
            <li>Legal Compliance: We may disclose information to comply with legal obligations, protect our rights, or respond to valid legal processes (e.g., subpoenas, warrants).</li>
            <li>Consent: We may share your information with third parties when we have obtained your explicit consent to do so.</li>
          </ul>
        </section>
        <section>
          <header>
            <h3>4. International Data Transfers</h3>
          </header>
          <p>Your personal information may be processed in countries other than the country in which you reside. These countries may have data protection laws that are different (and potentially less protective) than the laws in your country. By using our Services, you consent to the transfer of your information to countries outside your country of residence.</p>
        </section>
        <section>
          <header>
            <h3>5. Data Retention</h3>
          </header>
          <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy (e.g., maintaining your account, enabling transactions), unless a longer retention period is required by law.</p>
        </section>
        <section>
          <header>
            <h3>6. Security</h3>
          </header>
          <p>We employ appropriate technical and organizational measures to protect your personal information against accidental or unlawful destruction, loss, alteration, disclosure, or unauthorized access. However, no security measures are completely foolproof. You are responsible for protecting your account credentials and ensuring that you log out of shared devices.</p>
        </section>
        <section>
          <header>
            <h3>7. Children’s Privacy</h3>
          </header>
          <p>Our Services are not directed to individuals under the age of 13 (or other age as required by local law). We do not knowingly collect personal information from children. If you believe a child under this age has provided personal information to us, please contact us and we will take steps to delete such information.</p>
        </section>
        <section>
          <header>
            <h3>8. Your Choices and Rights</h3>
          </header>
          <p>Depending on your jurisdiction, you may have certain rights in relation to your personal information, such as:</p>
          <ul>
            <li>Access: The right to request access to the personal information we hold about you.</li>
            <li>Rectification: The right to request correction of any inaccurate or incomplete personal information.</li>
            <li>Deletion: The right to request deletion of your personal information, subject to certain legal exceptions.</li>
            <li>Objection: The right to object to the processing of your personal information if it is based on our legitimate interests.</li>
            <li>Withdrawal of Consent: Where processing is based on consent, you can withdraw your consent at any time.</li>
          </ul>
          <p>To exercise these rights, please contact us at the information provided below. We may require you to verify your identity before responding to such requests.</p>
        </section>
        <section>
          <header>
            <h3>9. Third-Party Links and Services</h3>
          </header>
          <p>Our Services may contain links to third-party websites, products, or services. This Privacy Policy does not apply to information collected by any third-party websites or services that you may access through such links. We encourage you to read the privacy policies of each website or service you visit.</p>
        </section>
        <section>
          <header>
            <h3>10. App Store Compliance</h3>
          </header>
          <ul>
            <li>Apple App Store: If you download or use our app from the Apple App Store, you acknowledge that this Privacy Policy and our Terms of Service are between you and Rescope, and not with Apple. Apple is not responsible for the app and its content.</li>
            <li>User Data Usage: We do not share user data with third parties for purposes unrelated to providing or improving our Services without your consent.</li>
            <li>Opt-Out: Where applicable, you can opt-out of certain data collection or uses by adjusting your device settings or by contacting us.</li>
          </ul>
        </section>
        <section>
          <header>
            <h3>11. Changes to This Privacy Policy</h3>
          </header>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we do, we will post the updated policy on our website or through our Services and update the “Last updated” date above. Your continued use of our Services after we post any modifications indicates your acceptance of the updated Privacy Policy.</p>
        </section>
        <section>
          <header>
            <h3>12. Contact Us</h3>
          </header>
          <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
          <p>Email: <strong>support@rescope.xyz</strong></p>
        </section>
      </div>
    </div>
  )
}